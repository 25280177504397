<template>
  <div class="radar-predictive">
    <iframe frameborder="0" height="100%" noborder :src="radarUri" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { URLS } from '@/config/urls.config'
import { getShortLocal } from '@/utils/string.util'

export default {
  name: 'CountyIntention',
  computed: {
    ...mapState({
      currentClient: state => state.client.currentClient,
      currentUser: state => state.backoffice.currentUser,
    }),
    radarUri() {
      return `${URLS.RADAR}/${this.currentClient.radarUuid}?view=lge_intention&locale=${getShortLocal(
        this.currentUser.locale
      )}`
    },
  },
}
</script>
